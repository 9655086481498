import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Index from '../pages/index/index';
import ManageCube from '../pages/manageCube/manageCube';
import MyselfInfo from '../pages/myselfInfo/myselfInfo';

function routers() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Index} />
				<Route exact path="/manage-cube" component={ManageCube} />
				<Route exact path="/myself-info" component={MyselfInfo} />
				<Redirect from='/' to='/' />
				<Redirect from='/manage-cube' to='/manage-cube' />
				<Redirect from='/myself-info' to='/myself-info' />
			</Switch>
		</Router>
	)
}

export default routers