import React, { Component } from 'react';
import { Button, Input, Layout, Menu, Modal, Popconfirm, } from 'antd';
import './myselfInfo.less';
import { Content, Header } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.svg';
import Sider from 'antd/lib/layout/Sider';
import Avatar from 'antd/lib/avatar/avatar';
import Close from '../../assets/img/close.svg';
interface State {
	verify_email_modal_show: boolean,
	change_email_modal_show: boolean,
	change_post_show: boolean,
}

class Index extends Component<any, State> {

	constructor(props: any) {

		super(props)
		this.state = {
			verify_email_modal_show: false,
			change_email_modal_show: false,
			change_post_show: false,
		}
	}

	/*验证邮箱modal*/
	onVerifyEmailModalShow(bool: boolean) {
		this.setState({
			verify_email_modal_show: bool
		})
	}

	/*更换邮箱modal*/
	onChangeEmailModalShow(bool: boolean) {
		this.setState({
			change_email_modal_show: bool
		})
	}

	/*更换身份modal*/
	onPostModalShow(bool: boolean) {
		this.setState({
			change_post_show: bool
		})
	}

	render() {
		return (
			<div className='myself-info-page'>
				<Layout className="layout-page">
					<Header>
						<Link to="/index">
							<div className='logo-box'>
								<img src={Logo} alt="logo" title="魔方管理" width="100%" />
							</div>
						</Link>
						<div className="login-box">
							<Link to="/manage-cube"><Button className="loging">进入管理 </Button></Link>
						</div>
					</Header>
					<Content>
						<Sider>
							<Menu
								mode="inline"
								defaultSelectedKeys={['1']}
								defaultOpenKeys={['sub1']}
								style={{ height: '100%' }}
								className="left-menu"
							>
								<Menu.Item key="1">个人资料</Menu.Item>
							</Menu>
						</Sider>
						<Content>
							<div className="content-box">
								<div className="set-list set-header">
									<div className="set-title">头像</div>
									<div className="set-content">
										<Avatar
											size={65}
											src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
											style={{ backgroundColor: '#eee' }}
										/>
									</div>
									<div className="set-edit"></div>
								</div>
								<div className="set-list">
									<div className="set-title">昵称</div>
									<div className="set-content">
										头发耶和华
									</div>
									<Popconfirm
										title={<Input className="invite-input" placeholder="新昵称" type="email" />}
										placement="right"
										// onConfirm={confirm}
										okText="确定"
										cancelText="取消"
										overlayClassName="rename"
									>
										<div className="set-edit"></div>
									</Popconfirm>
								</div>
								<div className="set-list">
									<div className="set-title">邮箱</div>
									<div className="set-content">
										103****261@qq.com
									</div>
									<div className="set-edit" onClick={this.onVerifyEmailModalShow.bind(this, true)} ></div>

									{/* 验证邮箱弹框 */}
									<Modal
										visible={this.state.verify_email_modal_show}
										onCancel={this.onVerifyEmailModalShow.bind(this, false)}
										// title="验证邮箱"
										footer={null}
										closeIcon={<img src={Close} alt="sd" />}
										width={320}
										wrapClassName="change-email-box"
									>
										<h3 className="modal-title">验证邮箱</h3>
										<Input placeholder="邮箱" size="large" type="email" />
										<div className="auth-code">
											<Input className="auth-code-input" placeholder="验证码" size="large" />
											<Button className="get-auth-code" type="primary">获取验证码</Button>
										</div>
										<Button onClick={this.onChangeEmailModalShow.bind(this, true)} className="binding-email-btn grayish-blue" type="primary">验证</Button>
									</Modal>

									{/* 更换邮箱弹框 */}
									<Modal
										visible={this.state.change_email_modal_show}
										onCancel={this.onChangeEmailModalShow.bind(this, false)}
										title="绑定邮箱"
										footer={null}
										closeIcon={<img src={Close} alt="sd" />}
										width={320}
										wrapClassName="change-email-box"
									>
										<Input placeholder="邮箱" size="large" type="email" />
										<div className="auth-code">
											<Input className="auth-code-input" placeholder="验证码" size="large" />
											<Button className="get-auth-code" type="primary">获取验证码</Button>
										</div>
										<Button className="binding-email-btn grayish-blue" type="primary">立即绑定</Button>
									</Modal>
								</div>

								<div className="set-list">
									<div className="set-title">身份</div>
									<div className="set-content">
										产品经理
									</div>
									<div onClick={this.onPostModalShow.bind(this, true)}  className="set-edit"></div>

									{/* 更换身份弹框 */}
									<Modal
										visible={this.state.change_post_show}
										onCancel={this.onPostModalShow.bind(this, false)}
										title="修改身份"
										footer={[
											<Button className="grayish-blue" key="submit" type="primary" >
												确定
											</Button>
										]}
										closeIcon={<img src={Close} alt="sd" />}
										width={400}
										wrapClassName="change-post-model"
									>
										<div className="post-list">
											<div className="post-item administrator administrator-check">管理者</div>
											<div className="post-item pm">产品经理</div>
											<div className="post-item designer">设计师</div>
											<div className="post-item developer-f">前端开发者</div>
											<div className="post-item developer-b">后端开发者</div>
										</div>
									</Modal>
								</div>
							</div>
						</Content>
					</Content>

				</Layout>
			</div >
		)
	}
}

export default Index;
