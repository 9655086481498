import React, { Component } from 'react';
import { Layout, Button, Modal } from 'antd';
// import Icon from '@ant-design/icons';
import './index.less';
import Logo from '../../assets/img/logo.svg';
import Close from '../../assets/img/close.svg';
import QR from '../../assets/img/QR.png';
import Subhead from '../../assets/img/subhead.png';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;
interface State {
	sign_in_modal_show: boolean,
}

class Index extends Component<any, State> {

	constructor(props: any) {
		super(props)
		this.state = {
			sign_in_modal_show: false,

		}
	}

	/*扫码注册弹框modal*/
	onSignInModalShow(bool: boolean) {
		this.setState({
			sign_in_modal_show: bool
		})
	}

	render() {
		// let { show_sider } = this.state;
		return (
			<div className='index-page'>
				<Layout className="layout-page">
					<Header>
						<Link to="/index">
							<div className='logo-box'>
								<img src={Logo} alt="logo" title="魔方管理" width="100%" />
							</div>
						</Link>
						<div className="login-box">
							<Button onClick={this.onSignInModalShow.bind(this, true)} className="logout">登录 ｜ 注册</Button>
							<Link to="/manage-cube"><Button className="loging">进入管理 </Button></Link>
						</div>

						{/* 扫码注册弹框 */}
						<Modal
							visible={this.state.sign_in_modal_show}
							onCancel={this.onSignInModalShow.bind(this, false)}
							footer={null}
							closeIcon={<img src={Close} alt="sd" />}
							wrapClassName="binding-email-box"
							width={650}
						>
							<div className="login-logo"></div>
							<div className="binding-email-content">
								<h2 className="login-title">微信扫码登录</h2>
								<p className="login-p"> 关注【魔方管理】微信公众号<br />进入系统并接收最新通知 </p>
								<div className="login-QRcode">
									<img src={QR} alt="二维码" />
								</div>
							</div>
						</Modal>
					</Header>
					<Content>
						<div className="content1">
							<h1 className="content1-h1">高效的任务制项目管理系统</h1>
							<h3 className="content1-h3"><img src={Subhead} alt="专为互联网团队打造" /></h3>
							<div className="preview-box">
								<Link to="/manage-cube">
									<Button className="enter-btn" type="primary">开始使用</Button>
								</Link>
							</div>
						</div>
						<div className="content2">
							<h1 className="content-h1">全流程管理</h1>
							<h3 className="content-h3">从需求脑暴、需求输出到UI设计、项目研发再到BUG管理、项目上线</h3>
							<h3 className="content-h3">魔方管理   <span>“ 一管到底 ”</span></h3>
							<div className="funcimg"></div>
						</div>
						<div className="content3">
							<div className="leftbox">
								<h1 className="content-h1">任务制分配</h1>
								<h3 className="content-h3">任务制分配需求，合理安排工作时间</h3>
								<h3 className="content-h3">让协作效率   <span>最大化</span></h3>
								<div className="btn-box">
									<Link to="/manage-cube">
										<Button className="start-use-btn" type="primary">开始使用</Button>
									</Link>
								</div>
							</div>
							<div className="rightbox"></div>
						</div>
						<div className="content4">
							<div className="leftbox"></div>
							<div className="rightbox">
								<h1 className="content-h1">项目动态<br />清晰可见</h1>
								<h3 className="content-h3">免去日报、周报、月报的旧传统<br />实时的项目动态，让项目自己说话</h3>
								<div className="btn-box">
									<Link to="/manage-cube">
										<Button className="start-use-btn" type="primary">开始使用</Button>
									</Link>
								</div>
							</div>
						</div>
						<div className="content5">
							<h1 className="content-h1">总结过去<br />展望未来</h1>
							<h3 className="content-h3">每一个项目都是一次进步的机会<br />总结过去的不足，才能迎来更好的未来</h3>
							<div className="btn-box">
								<Link to="/manage-cube">
									<Button className="start-use-btn" type="primary">开始使用</Button>
								</Link>
							</div>
						</div>
						<div className="content6">
							<h1 className="content-h2">项目报表</h1>
							<div className="report-img"></div>
							<div className="btn-box">
								<Link to="/manage-cube">
									<Button className="start-use-btn" type="primary">开始使用</Button>
								</Link>
							</div>
						</div>
						<div className="page-footer">
							© 2021 ·Katerina Limpitsouni·保留所有权利
						</div>
					</Content>
				</Layout>
			</div >
		)
	}
}

export default Index;
