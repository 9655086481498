import React, { Component, useState } from 'react';
import { Layout, Row, Col, Form, Input, Select, Button, Dropdown, Modal, Drawer, Popconfirm } from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	CaretRightFilled,
	CaretDownOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons';
import './manageCube.less';
import Logo from '../../assets/img/logo.svg';
import Member from '../../assets/img/member.svg';
import Dynamic from '../../assets/img/dynamic.svg';
import Document from '../../assets/img/document.svg';
import Thought from '../../assets/img/thought.svg';
import Demand from '../../assets/img/demand.svg';
import Design from '../../assets/img/design.svg';
import Procedure from '../../assets/img/procedure.svg';
import Del from '../../assets/img/delete.svg';
import Edit from '../../assets/img/edit.svg';
import Add from '../../assets/img/add.svg';
import Close from '../../assets/img/close.svg';
import Droparrow from '../../assets/img/drop_arrow.svg';
import Upload from '../../assets/img/upload.svg';
import { Link } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import QR from '../../assets/img/QR.png';

function UserHeadHooks() {
	const [showMenu, setShowMenu] = useState(false);
	const [, setShowMenu2] = useState(false);

	const menu = (
		<div className="user-manage">
			<Link to="/myself-info">
				<div className="user-name red">张小三</div>
			</Link>
			<div className="user-list">
				<span className="left">身份</span>
				<span className="right red-color"> 产品经理</span>
			</div>
			<div className="user-list">
				<span className="left">消息</span>
				<span className="right red-color"> NEW!</span>
			</div>
			<div className="user-list">
				<span className="left">成员管理</span>
				<span className="right"> 6人</span>
			</div>
			<div className="login-out red-color">退出登录</div>
		</div>
	);

	const menu2 = (
		<div className="online-box">
			<p className="online">在线</p>
			<div className="online-list">
				<img className='headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
				<span className="name red-color">张小三</span>
			</div>
			<div className="online-list">
				<img className='headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
				<span className="name green-color">张小三</span>
			</div>
			<div className="online-list">
				<img className='headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
				<span className="name green-color">张小三</span>
			</div>
			<div className="online-list">
				<img className='headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
				<span className="name green-color">张小三</span>
			</div>
		</div>
	);

	return (
		<div className="hearder-box">
			<Button className="header-left yellow" key="submit" type="primary" >
				神奇魔方
			</Button>
			<div className="header-right">
				<div className="text">
					我们倾听每一位使用者的声音！
				</div>
				<div className="feedback">
					使用反馈
				</div>
				<Dropdown
					onVisibleChange={(e: boolean) => setShowMenu2(e)}
					overlay={menu2}
					placement="bottomCenter"
				>
					<div className="online-user">
						<img className='headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
						<img className='headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
						<img className='headimg purple-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
					</div>
				</Dropdown>
				<Dropdown
					onVisibleChange={(e: boolean) => setShowMenu(e)}
					overlay={menu}
					placement="bottomLeft"
					arrow
				>
					<div className={showMenu ? 'user-head active' : 'user-head'}>
						<img className='headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
						<CaretDownOutlined className='icon' />
					</div>
				</Dropdown>
			</div>
		</div>
	)
}

function ProjectManage() {

	// 项目管理下拉框
	const [showMenu3, setShowMenu3] = useState(false);
	const menu3 = (
		<div className="project-manage">
			<Popconfirm
				title={<Input className="invite-input" placeholder="项目重命名" type="email" />}
				icon={null}
				// onConfirm={confirm}
				// onCancel={cancel}
				okText="确定"
				cancelText="取消"
				overlayClassName="rename"
			>
				<div className="project-manage-item">重命名</div>
			</Popconfirm>
			<Popconfirm
				title="确定删除这个项目？"
				// onConfirm={confirm}
				// onCancel={cancel}
				okText="确定"
				cancelText="取消"
			>
				<div className="project-manage-item red-color">删除</div>
			</Popconfirm>
		</div>
	);

	return (
		<Dropdown
			onVisibleChange={(e: boolean) => setShowMenu3(e)}
			overlay={menu3}
			trigger={['click']}
			placement="bottomCenter"
		>
			<div className={showMenu3 ? 'more active' : 'more'}></div>
		</Dropdown>
	)
}

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
interface State {
	show_sider: boolean,
	show_think_drawer: boolean,
	show_target_drawer: boolean,
	show_needs_drawer: boolean,
	show_ui_drawer: boolean,
	show_dev_drawer: boolean,
	show_bug_drawer: boolean,
	add_think_modal_show: boolean,
	add_doc_modal_show: boolean,
	check_think_modal_show: boolean,
	sign_in_modal_show: boolean,
	binding_email_modal_show: boolean,
	check_member_modal_show: boolean,
	member_manage_modal_show: boolean,
	message_modal_show: boolean,
}

class Index extends Component<any, State> {

	constructor(props: any) {
		super(props)
		this.state = {
			show_sider: false,
			show_think_drawer: false,
			show_target_drawer: false,
			show_needs_drawer: false,
			show_ui_drawer: false,
			show_dev_drawer: false,
			show_bug_drawer: false,
			add_think_modal_show: false,
			add_doc_modal_show: false,
			check_think_modal_show: false,
			sign_in_modal_show: true,
			binding_email_modal_show: true,
			check_member_modal_show: false,
			member_manage_modal_show: false,
			message_modal_show: false,
		}
	}

	componentDidMount() {
		console.log(this.props.match)
	}

	onShowSilder(bool: boolean) {
		this.setState({
			show_sider: bool
		})
	}

	onShowThinkDrawer(bool: boolean) {
		this.setState({
			show_think_drawer: bool
		})
	}

	onShowTargetDrawer(bool: boolean) {
		this.setState({
			show_target_drawer: bool
		})
	}

	onShowNeedsDrawer(bool: boolean) {
		this.setState({
			show_needs_drawer: bool
		})
	}

	onShowUiDrawer(bool: boolean) {
		this.setState({
			show_ui_drawer: bool
		})
	}

	onShowDevDrawer(bool: boolean) {
		this.setState({
			show_dev_drawer: bool
		})
	}

	onShowBugDrawer(bool: boolean) {
		this.setState({
			show_dev_drawer: bool
		})
	}

	onDelThink() {
		Modal.confirm({
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确认删除？',
			okText: '确认',
			cancelText: '取消'
		});
	}

	/*控制显示添加脑暴想法modal*/
	onAddThinkModalShow(bool: boolean) {
		this.setState({
			add_think_modal_show: bool
		})
	}

	/*控制显示添加文档modal*/
	onAddDocModalShow(bool: boolean) {
		this.setState({
			add_doc_modal_show: bool
		})
	}

	/*控制查看脑暴modal*/
	onCheckThinkModalShow(bool: boolean) {
		this.setState({
			check_think_modal_show: bool
		})
	}

	/*扫码注册弹框modal*/
	onSignInModalShow(bool: boolean) {
		this.setState({
			sign_in_modal_show: bool
		})
	}

	/*控制绑定邮箱modal*/
	onBindingEmailModalShow(bool: boolean) {
		this.setState({
			binding_email_modal_show: bool
		})
	}

	/*查看成员modal*/
	onCheckMemberModalShow(bool: boolean) {
		this.setState({
			check_member_modal_show: bool
		})
	}

	/*成员管理model*/
	onMemberManageModalShow(bool: boolean) {
		this.setState({
			member_manage_modal_show: bool
		})
	}

	/*消息model*/
	onMessageModalShow(bool: boolean) {
		this.setState({
			message_modal_show: bool
		})
	}

	render() {
		let { show_sider } = this.state;
		return (
			<div className='manageCube-page'>
				<Layout className="layout-page">
					<Sider className='sider' collapsible collapsed={show_sider} trigger={null} collapsedWidth={50}>
						<div className='sider-container'>
							<div className='logo-box'>
								<img src={Logo} alt="1" width="100%" />
							</div>
							<div className='object-list object-list-box'>
								<div className='object-item object-item-pick'>
									<div className="object-item-content yellow">
										神奇魔方神奇魔方神奇魔方神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content blue">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content red">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content purple">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content green">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content red">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<div className='object-item'>
									<div className="object-item-content yellow">
										神奇魔方
										<ProjectManage />
									</div>
								</div>
								<Popconfirm
									title={<Input className="invite-input" placeholder="项目名称" type="email" />}
									icon={null}
									// onConfirm={confirm}
									// onCancel={cancel}
									placement="rightTop"
									okText="确定"
									cancelText="取消"
									overlayClassName="rename"
								>
									<div className='object-add'>
										<div className="object-item-content">
											<img className='icon' src={Add} alt="添加项目" /> 添加项目
										</div>
									</div>
								</Popconfirm>


							</div>
						</div>
					</Sider>
					<Layout className="layout-right">
						<Header className='header'>
							<div className='show-silder-btn'>
								{
									show_sider &&
									<MenuFoldOutlined onClick={this.onShowSilder.bind(this, false)} className='icon' />
								}
								{
									!show_sider &&
									<MenuUnfoldOutlined onClick={this.onShowSilder.bind(this, true)} className='icon' />
								}
							</div>
							<UserHeadHooks />
						</Header>
						<Content className='content'>
							<Row>
								<Col lg={8} sm={24} xs={24}>
									<div className='col-header'>
										<div className='col-title'>
											<img className='icon' src={Member} alt="成员" />
											<h2 className='text'>项目成员</h2>
										</div>
									</div>
									<div className='object-members-list'>
										<div className='object-members-item' onClick={this.onCheckMemberModalShow.bind(this, true)}>
											<div className='members-head'>
												<img className='members-headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
												<span className='members-name green-color'>但还是分开是大是大非觉得舒服</span>
											</div>
											<div className='members-jobs'>前端开发者</div>
										</div>
										<div className='object-members-item' onClick={this.onCheckMemberModalShow.bind(this, true)}>
											<div className='members-head'>
												<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
												<span className='members-name red-color'>马化腾</span>
											</div>
											<div className='members-jobs'>产品经理</div>
										</div>
										<div className='object-members-item' onClick={this.onCheckMemberModalShow.bind(this, true)}>
											<div className='members-head'>
												<img className='members-headimg purple-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
												<span className='members-name purple-color'>马化腾</span>
											</div>
											<div className='members-jobs'>设计师</div>
										</div>
										<div className='object-members-item' onClick={this.onCheckMemberModalShow.bind(this, true)}>
											<div className='members-head'>
												<img className='members-headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
												<span className='members-name green-color'>马化腾</span>
											</div>
											<div className='members-jobs'>前端开发者</div>
										</div>
										<div className='object-members-item' onClick={this.onCheckMemberModalShow.bind(this, true)}>
											<div className='members-head'>
												<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
												<span className='members-name red-color'>马化腾</span>
											</div>
											<div className='members-jobs'>产品经理</div>
										</div>
									</div>

									{/* 查看成员弹框 */}
									<Modal
										visible={this.state.check_member_modal_show}
										onCancel={this.onCheckMemberModalShow.bind(this, false)}
										footer={null}
										closeIcon={<img src={Close} alt="X" />}
										width={460}
									>
										<div className="check-member-box">
											<img className="member-head green-border" src="https://www.w3school.com.cn/i/eg_tulip.jpg" alt="头像" />
											<p className="member-name green-color">张小三</p>
											<p className="member-jobs">前端开发者</p>
											<p className="member-join-date">2021/04/29 18:29:29  加入示例项目</p>
											<p className="inviter">邀请人：张小三</p>
											<Button className="member-manage grayish-blue" key="submit" type="primary" onClick={this.onMemberManageModalShow.bind(this, true)}>
												成员管理
											</Button>
										</div>
									</Modal>
								</Col>
								<Col lg={8} sm={24} xs={24}>
									<div className='col-header'>
										<div className='col-title'>
											<img className='icon' src={Dynamic} alt="动态" />
											<h2 className='text'>项目动态</h2>
										</div>
									</div>
									<div className='object-dynamic-list'>
										<div className='object-dynamic-item'>
											<div className='dynamic'>
												XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态
											</div>
											<CaretRightFilled className="icon" />
										</div>
										<div className='object-dynamic-item object-dynamic-item-remind'>
											<div className='dynamic'>
												XXX动态XXX动态XXX动态XXX动态
											</div>
											<CaretRightFilled className="icon" />
										</div>
										<div className='object-dynamic-item object-dynamic-item-check'>
											<div className='dynamic'>
												XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态XXX动态
											</div>
											<CaretRightFilled className="icon" />
										</div>
										<div className='object-dynamic-item'>
											<div className='dynamic'>
												XXX动态XXX动态XXX动态XXX动态
											</div>
											<CaretRightFilled className="icon" />
										</div>
									</div>
								</Col>
								<Col lg={8} sm={24} xs={24}>
									<div className='col-header'>
										<div className='col-title'>
											<img className='icon' src={Document} alt="文档" />
											<h2 className='text'>文档管理</h2>
										</div>
									</div>
									<div className='object-document-list'>
										<div className="object-document-row">
											<div className="object-document-item-half" onClick={this.onShowThinkDrawer.bind(this, true)}>
												<div className='object-document-item yellow'>
													<div className="document-icon">
														<img src={Thought} alt="脑暴" />
													</div>
													<div className="document-text">脑暴管理</div>
												</div>
											</div>
											<div className="object-document-item-half">
												<div className='object-document-item blur1'></div>
											</div>
										</div>
										<div className='object-document-item red' onClick={this.onShowNeedsDrawer.bind(this, true)}>
											<div className="document-icon">
												<img src={Demand} alt="需求" />
											</div>
											<div className="document-text">需求文档</div>
										</div>
										<div className='object-document-item purple' onClick={this.onShowUiDrawer.bind(this, true)}>
											<div className="document-icon">
												<img src={Design} alt="设计" />
											</div>
											<div className="document-text">UI设计</div>
										</div>
										<div className="object-document-row">
											<div className="object-document-item-half" onClick={this.onShowDevDrawer.bind(this, true)}>
												<div className='object-document-item green'>
													<div className="document-icon">
														<img src={Procedure} alt="开发" />
													</div>
													<div className="document-text">开发文档</div>
												</div>
											</div>
											<div className="object-document-item-half" >
												<div className='object-document-item blur2'></div>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Content>
					</Layout>
				</Layout>

				{/* 消息弹框 */}
				<Modal
					visible={this.state.message_modal_show}
					onCancel={this.onMessageModalShow.bind(this, false)}
					footer={null}
					closeIcon={<img src={Close} alt="X" />}
					wrapClassName="message-modal"
				>
					<h2 className='brain-think-title'>消息</h2>
					<div className='message-list'>
						<div className='message-item'>
							<div className='message-box'>
								<span className="message-new">NEW!</span>
								<span className='message-text'>XXX邀请你加入【神奇魔方】项目组都是非法所得开了房间的沙发就开始对南非空军大脑神经妇女三等奖分手的分手的</span>
							</div>
							<div className="message-btn-box">
								<Button className="lose" key="submit" type="primary" >忽略</Button>
								<Button className="pass grayish-blue" key="submit" type="primary" >同意</Button>
							</div>
						</div>
						<div className='message-item'>
							<div className='message-box'>
								<span className="message-new">NEW!</span>
								<span className='message-text'>XXX邀请你加入【神奇魔方】项目</span>
							</div>
							<div className="message-btn-box">
								<Button className="lose" key="submit" type="primary" >忽略</Button>
								<Button className="pass grayish-blue" key="submit" type="primary" >同意</Button>
							</div>
						</div>
						<div className='message-item'>
							<div className='message-box'>
								{/* <span className="message-new">NEW!</span> */}
								<span className='message-text'>XXX邀请你加入【神奇魔方】项目</span>
							</div>
							<div className="message-btn-box">
								已忽略
							</div>
						</div>
						<div className='message-item'>
							<div className='message-box'>
								{/* <span className="message-new">NEW!</span> */}
								<span className='message-text'>XXX邀请你加入【神奇魔方】项目</span>
							</div>
							<div className="message-btn-box">
								已同意
							</div>
						</div>
						<div className='message-item'>
							<div className='message-box'>
								{/* <span className="message-new">NEW!</span> */}
								<span className='message-text'>XXX邀请你加入【神奇魔方】项目</span>
							</div>
							<div className="message-btn-box">
								已同意
							</div>
						</div>
					</div>
				</Modal>

				{/* 成员管理弹框 */}
				<Modal
					visible={this.state.member_manage_modal_show}
					onCancel={this.onMemberManageModalShow.bind(this, false)}
					footer={null}
					closeIcon={<img src={Close} alt="X" />}
					width={420}
					wrapClassName="invite-member"
				>
					<h2 className='brain-think-title'>成员管理</h2>
					<div className="invite-email">
						<Input className="invite-input" placeholder="请输入邮箱" size="large" type="email" />
						<Button className="invite-btn grayish-blue" type="primary">邀请</Button>
					</div>
					<div className="member-item me">
						<div className="head-box">
							<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
							<span className='members-name red-color'>（我）马化腾</span>
						</div>
						<Form
							name="addThink"
						>
							<Form.Item>
								<Select
									style={{ width: 100, fontSize: 14 }}
									placeholder="请选择…"
									bordered={false}
									optionFilterProp="children"
									defaultValue="产品经理"
									suffixIcon={<img src={Droparrow} alt="开发" />}
								>
									<Select.Option value="前端开发者">前端开发者</Select.Option>
									<Select.Option value="产品经理">产品经理</Select.Option>
									<Select.Option value="设计师">设计师</Select.Option>
									<Select.Option value="后端开发者">后端开发者</Select.Option>
								</Select>
							</Form.Item>
						</Form>
					</div>
					<div className="member-list">
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg grayish-blue-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name grayish-blue-color'>马化腾</span>
							</div>
							<span className="inviting">邀请中…</span>
						</div>
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg purple-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name purple-color'>马化腾的房间散发健康的是愤怒的说方上</span>
							</div>
							<div className="control">
								<Form
									name="addThink"
								>
									<Form.Item>
										<Select
											style={{ width: 100, fontSize: 14 }}
											placeholder="请选择…"
											bordered={false}
											optionFilterProp="children"
											defaultValue="设计师"
											suffixIcon={<img src={Droparrow} alt="开发" />}
										>
											<Select.Option value="前端开发者">前端开发者</Select.Option>
											<Select.Option value="产品经理">产品经理</Select.Option>
											<Select.Option value="设计师">设计师</Select.Option>
											<Select.Option value="后端开发者">后端开发者</Select.Option>
										</Select>
									</Form.Item>
								</Form>
								<div className="delete"></div>
							</div>
						</div>
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name red-color'>马化腾</span>
							</div>
							<div className="control">
								<Form
									name="addThink"
								>
									<Form.Item>
										<Select
											style={{ width: 100, fontSize: 14 }}
											placeholder="请选择…"
											bordered={false}
											optionFilterProp="children"
											defaultValue="产品经理"
											suffixIcon={<img src={Droparrow} alt="开发" />}
										>
											<Select.Option value="前端开发者">前端开发者</Select.Option>
											<Select.Option value="产品经理">产品经理</Select.Option>
											<Select.Option value="设计师">设计师</Select.Option>
											<Select.Option value="后端开发者">后端开发者</Select.Option>
										</Select>
									</Form.Item>
								</Form>
								<div className="delete"></div>
							</div>
						</div>
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name red-color'>马化腾</span>
							</div>
							<div className="control">
								<Form
									name="addThink"
								>
									<Form.Item>
										<Select
											style={{ width: 100, fontSize: 14 }}
											placeholder="请选择…"
											bordered={false}
											optionFilterProp="children"
											defaultValue="产品经理"
											suffixIcon={<img src={Droparrow} alt="开发" />}
										>
											<Select.Option value="前端开发者">前端开发者</Select.Option>
											<Select.Option value="产品经理">产品经理</Select.Option>
											<Select.Option value="设计师">设计师</Select.Option>
											<Select.Option value="后端开发者">后端开发者</Select.Option>
										</Select>
									</Form.Item>
								</Form>
								<div className="delete"></div>
							</div>
						</div>
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg red-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name red-color'>马化腾</span>
							</div>
							<div className="control">
								<Form
									name="addThink"
								>
									<Form.Item>
										<Select
											style={{ width: 100, fontSize: 14 }}
											placeholder="请选择…"
											bordered={false}
											optionFilterProp="children"
											defaultValue="产品经理"
											suffixIcon={<img src={Droparrow} alt="开发" />}
										>
											<Select.Option value="前端开发者">前端开发者</Select.Option>
											<Select.Option value="产品经理">产品经理</Select.Option>
											<Select.Option value="设计师">设计师</Select.Option>
											<Select.Option value="后端开发者">后端开发者</Select.Option>
										</Select>
									</Form.Item>
								</Form>
								<div className="delete"></div>
							</div>
						</div>
						<div className="member-item">
							<div className="head-box">
								<img className='members-headimg green-border' alt='马化腾' src='https://www.w3school.com.cn/i/eg_tulip.jpg' />
								<span className='members-name green-color'>马化腾</span>
							</div>
							<div className="control">
								<Form
									name="addThink"
								>
									<Form.Item>
										<Select
											style={{ width: 100, fontSize: 14 }}
											placeholder="请选择…"
											bordered={false}
											optionFilterProp="children"
											defaultValue="前端开发者"
											suffixIcon={<img src={Droparrow} alt="开发" />}
										>
											<Select.Option value="前端开发者">前端开发者</Select.Option>
											<Select.Option value="产品经理">产品经理</Select.Option>
											<Select.Option value="设计师">设计师</Select.Option>
											<Select.Option value="后端开发者">后端开发者</Select.Option>
										</Select>
									</Form.Item>
								</Form>
								<div className="delete"></div>
							</div>
						</div>
					</div>
				</Modal>

				{/* 脑暴管理抽屉 */}
				<Drawer
					onClose={this.onShowThinkDrawer.bind(this, false)}
					closable={false}
					maskClosable={true}
					visible={this.state.show_think_drawer}
					title="脑暴管理"
					placement="right"
					width="78%"
				>
					{/* 脑暴管理 */}
					<div className='drawer-header'>
						<div className="add-btn yellow" onClick={this.onAddThinkModalShow.bind(this, true)}>
							<img src={Add} alt="添加脑暴" /> 添加脑暴
						</div>
						<Modal
							visible={this.state.add_think_modal_show}
							onCancel={this.onAddThinkModalShow.bind(this, false)}
							// title="新建脑暴"
							footer={[
								<Button className="yellow" key="submit" type="primary">
									发表
								</Button>
							]}
							closeIcon={<img src={Close} alt="sd" />}
						>
							<Form
								name="addThink"
							>
								<Form.Item>
									<Select
										style={{ width: 94, fontSize: 16, fontWeight: 'bold' }}
										placeholder="请选择…"
										bordered={false}
										optionFilterProp="children"
										defaultValue="新想法"
										suffixIcon={<img src={Droparrow} alt="开发" />}
									>
										<Select.Option value="前端开发者">前端开发者</Select.Option>
										<Select.Option value="产品经理">产品经理</Select.Option>
										<Select.Option value="设计师">设计师</Select.Option>
										<Select.Option value="后端开发者">后端开发者</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item>
									<TextArea
										placeholder="请输入内容…"
										autoSize={{ minRows: 8, maxRows: 12 }}
									/>
								</Form.Item>
							</Form>
						</Modal>

						<div className="display-style">
							<div className="grid"></div>
							<div className="list"></div>
						</div>
					</div>

					{/* 网格样式 */}
					<div className='brain-think-grid'>
						<Row gutter={[20, 20]}>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>新想法</h2>
									<p className='brain-think-desc'>
										这是我的新想法烟烟烟这是我的烟这是我的烟这是我的烟这是我的烟这是我的烟这是我的烟这是我的烟这是我的
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>修改意见</h2>
									<p className='brain-think-desc'>
										设计能不能像这样，颜色调成五彩斑斓的黑？
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>新需求</h2>
									<p className='brain-think-desc'>
										设计能不能像这样，颜色调成五彩斑斓的黑？
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>有个建议</h2>
									<p className='brain-think-desc'>
										设计能不能像这样，颜色调成五彩斑斓的黑？
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>有个建议</h2>
									<p className='brain-think-desc'>
										设计能不能像这样，颜色调成五彩斑斓的黑？
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
									<h2 className='brain-think-title'>有个建议</h2>
									<p className='brain-think-desc'>
										设计能不能像这样，颜色调成五彩斑斓的黑？
								    </p>
									<div className="operate">
										<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>

					{/* 列表样式 */}
					<div className='brain-think-list'>
						<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
							<h2 className='brain-think-title'>修改意见</h2>
							<p className='brain-think-desc'>
								设计能不能像这样，颜色调成五彩斑斓的黑？
							</p>
							<div className="operate">
								<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
						</div>
						<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
							<h2 className='brain-think-title'>新需求</h2>
							<p className='brain-think-desc'>
								设计能不能像这样，颜色调成五彩斑斓的黑？
							</p>
							<div className="operate">
								<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
						</div>
						<div className='brain-think-item' onClick={this.onCheckThinkModalShow.bind(this, true)}>
							<h2 className='brain-think-title'>有个建议有个建议有个建议有个建议有个建议有个建议有个建议有个建议有个建议有个建议</h2>
							<p className='brain-think-desc'>
								设计能不能像这样，颜色调成五彩斑斓的黑？设计能不能像这样，颜色调成五彩斑斓的黑？设计能不能像这样，颜色调成五彩斑斓的黑？设计能不能像这样，颜色调成五彩斑斓的黑？设计能不能像这样，颜色调成五彩斑斓的黑？
							</p>
							<div className="operate">
								<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
						</div>
					</div>

					<Modal
						visible={this.state.check_think_modal_show}
						onCancel={this.onCheckThinkModalShow.bind(this, false)}
						footer={null}
						closeIcon={<img src={Close} alt="sd" />}
					>
						<div className="modal-title-box">
							<h3 className="modal-title">修改意见</h3>
							<div className="modal-title-explain">
								<img className="head red-border" src="https://www.w3school.com.cn/i/eg_tulip.jpg" alt="头像" />
								<span className="name red-color">张小三</span>
								<span className="date">2020/06/02 18:30:30</span>
							</div>
						</div>
						<p className="brain-think-content">
							设计能不能像这样，颜色调成五彩斑斓的黑？但是你分开是对你发的啥饭的生命方面的，是发多少，魔方，都没睡方面，是的父母，的什么饭多少
									</p>
						<div className="operate">
							<div className="btn" onClick={this.onAddThinkModalShow.bind(this, true)}>
								<img src={Edit} alt="编辑" />
							</div>
							<div className="btn" onClick={this.onDelThink.bind(this)}>
								<img src={Del} alt="删除" />
							</div>
						</div>
					</Modal>
				</Drawer>

				{/* 需求管理抽屉 */}
				<Drawer
					onClose={this.onShowNeedsDrawer.bind(this, false)}
					closable={false}
					maskClosable={true}
					visible={this.state.show_needs_drawer}
					title="需求文档"
					placement="right"
					width="78%"
				>
					<div className='drawer-header'>
						<div className="add-btn red" onClick={this.onAddDocModalShow.bind(this, true)}>
							<img src={Add} alt="添加脑暴" /> 添加文档
						</div>

						<Modal
							visible={this.state.add_doc_modal_show}
							onCancel={this.onAddDocModalShow.bind(this, false)}
							footer={[
								<Button className="red" key="submit" type="primary">
									发表
								</Button>
							]}
							closeIcon={<img src={Close} alt="X" />}
						>
							<h3 className="modal-title">添加需求文档</h3>
							<div className="upload-type">
								<div className="add-type add-type-pick">添加链接</div>
								<div className="add-type">上传附件</div>
							</div>
							<Form
								name="addThink"
							>
								<Form.Item>
									<TextArea
										placeholder="请输入链接地址…"
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								</Form.Item>
							</Form>
							<div className="add-file">
								<Dragger>
									<div className="icon-box">
										<img src={Upload} alt="上传附件" />
									</div>
									<div className="text-box">
										<h2 className="add-file-title">将文件拖拽到这里，或点击上传</h2>
										<p className="add-file-explain">限50Mb以内</p>
									</div>
								</Dragger>
							</div>
						</Modal>

						<div className="display-style">
							<div className="grid"></div>
							<div className="list"></div>
						</div>
					</div>

					{/* 网格样式 */}
					<div className='brain-think-grid'>
						<Row gutter={[20, 20]}>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item link'>
									<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称</h2>
									<div className="outside-box">
										<Button className="open red" key="submit" type="primary" >
											打开链接
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open red" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open red" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>

					{/* 列表样式 */}
					<div className='brain-think-list'>
						<div className='brain-think-item link'>
							<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open red" key="submit" type="primary" >
								打开链接
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open red" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open red" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
					</div>
				</Drawer>


				{/* UI管理抽屉 */}
				<Drawer
					onClose={this.onShowUiDrawer.bind(this, false)}
					closable={false}
					maskClosable={true}
					visible={this.state.show_ui_drawer}
					title="UI设计"
					placement="right"
					width="78%"
				>
					<div className='drawer-header'>
						<div className="add-btn purple" onClick={this.onAddDocModalShow.bind(this, true)}>
							<img src={Add} alt="添加脑暴" /> 添加文档
						</div>

						<Modal
							visible={this.state.add_doc_modal_show}
							onCancel={this.onAddDocModalShow.bind(this, false)}
							footer={[
								<Button className="purple" key="submit" type="primary" loading={true}>
									发表
								</Button>
							]}
							closeIcon={<img src={Close} alt="sd" />}
						>
							<h3 className="modal-title">添加需求文档</h3>
							<div className="upload-type">
								<div className="add-type add-type-pick">添加链接</div>
								<div className="add-type">上传附件</div>
							</div>
							<Form
								name="addThink"
							>
								<Form.Item>
									<TextArea
										placeholder="请输入链接地址…"
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								</Form.Item>
							</Form>
							<div className="add-file">
								<div className="icon-box">
									<img src={Upload} alt="上传附件" />
								</div>
								<div className="text-box">
									<h2 className="add-file-title">将文件拖拽到这里，或点击上传</h2>
									<p className="add-file-explain">限50Mb以内</p>
								</div>
							</div>
						</Modal>

						<div className="display-style">
							<div className="grid"></div>
							<div className="list"></div>
						</div>
					</div>

					{/* 网格样式 */}
					<div className='brain-think-grid'>
						<Row gutter={[20, 20]}>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item link'>
									<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称</h2>
									<div className="outside-box">
										<Button className="open purple" key="submit" type="primary" >
											打开链接
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open purple" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open purple" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>

					{/* 列表样式 */}
					<div className='brain-think-list'>
						<div className='brain-think-item link'>
							<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open purple" key="submit" type="primary" >
								打开链接
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open purple" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open purple" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
					</div>
				</Drawer>

				{/* 开发管理抽屉 */}
				<Drawer
					onClose={this.onShowDevDrawer.bind(this, false)}
					closable={false}
					maskClosable={true}
					visible={this.state.show_dev_drawer}
					title="开发文档"
					placement="right"
					width="78%"
				>
					<div className='drawer-header'>
						<div className="add-btn green" onClick={this.onAddDocModalShow.bind(this, true)}>
							<img src={Add} alt="添加脑暴" /> 添加文档
						</div>

						<Modal
							visible={this.state.add_doc_modal_show}
							onCancel={this.onAddDocModalShow.bind(this, false)}
							footer={[
								<Button className="green" key="submit" type="primary" loading={true}>
									发表
								</Button>
							]}
							closeIcon={<img src={Close} alt="sd" />}
						>
							<h3 className="modal-title">添加需求文档</h3>
							<div className="upload-type">
								<div className="add-type add-type-pick">添加链接</div>
								<div className="add-type">上传附件</div>
							</div>
							<Form
								name="addThink"
							>
								<Form.Item>
									<TextArea
										placeholder="请输入链接地址…"
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								</Form.Item>
							</Form>
							<div className="add-file">
								<div className="icon-box">
									<img src={Upload} alt="上传附件" />
								</div>
								<div className="text-box">
									<h2 className="add-file-title">将文件拖拽到这里，或点击上传</h2>
									<p className="add-file-explain">限50Mb以内</p>
								</div>
							</div>
						</Modal>

						<div className="display-style">
							<div className="grid"></div>
							<div className="list"></div>
						</div>
					</div>

					{/* 网格样式 */}
					<div className='brain-think-grid'>
						<Row gutter={[20, 20]}>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item link'>
									<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称</h2>
									<div className="outside-box">
										<Button className="open green" key="submit" type="primary" >
											打开链接
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open green" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} sm={8} xs={24}>
								<div className='brain-think-item file'>
									<h2 className='brain-think-title'>文档名称</h2>
									<div className="outside-box">
										<Button className="open green" key="submit" type="primary" >
											下载附件
										</Button>
									</div>
									<div className="operate">
										<div className="btn">
											<img src={Edit} alt="编辑" />
										</div>
										<div className="btn" onClick={this.onDelThink.bind(this)}>
											<img src={Del} alt="删除" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>

					{/* 列表样式 */}
					<div className='brain-think-list'>
						<div className='brain-think-item link'>
							<h2 className='brain-think-title'>文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open green" key="submit" type="primary" >
								打开链接
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open green" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
						<div className='brain-think-item file'>
							<h2 className='brain-think-title'>文档名称</h2>
							<div className="operate">
								<div className="btn">
									<img src={Edit} alt="编辑" />
								</div>
								<div className="btn" onClick={this.onDelThink.bind(this)}>
									<img src={Del} alt="删除" />
								</div>
							</div>
							<Button className="open green" key="submit" type="primary" >
								下载附件
							</Button>
						</div>
					</div>
				</Drawer>

				{/* 扫码注册弹框 */}
				<Modal
					visible={this.state.sign_in_modal_show}
					onCancel={this.onSignInModalShow.bind(this, false)}
					footer={null}
					closeIcon={<img src={Close} alt="sd" />}
					wrapClassName="binding-email-box"
					width={650}
				>
					<div className="login-logo"></div>
					<div className="binding-email-content">
						<h2 className="login-title">微信扫码登录</h2>
						<p className="login-p"> 关注【魔方管理】微信公众号<br />进入系统并接收最新通知 </p>
						<div className="login-QRcode">
							<img src={QR} alt="二维码" />
						</div>
					</div>
				</Modal>

				{/* 绑定邮箱弹框 */}
				<Modal
					visible={this.state.binding_email_modal_show}
					onCancel={this.onBindingEmailModalShow.bind(this, false)}
					// title="新建脑暴"
					footer={null}
					closeIcon={<img src={Close} alt="sd" />}
					wrapClassName="binding-email-box"
					width={650}
				>
					<div className="binding-email-logo"></div>
					<div className="binding-email-content">
						<h2 className="binding-email-title">绑定邮箱</h2>
						<Input placeholder="邮箱" size="large" type="email" />
						<div className="auth-code">
							<Input className="auth-code-input" placeholder="验证码" size="large" />
							<Button className="get-auth-code" type="primary">获取验证码</Button>
						</div>
						<Button className="binding-email-btn grayish-blue" type="primary">立即绑定</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default Index;
